<template>
  <div class="w-100 pl-2 pr-4">
    <v-col
      cols="12"
      class="mt-2"
    >
      <div class="d-flex">
        <v-btn
          color="primary"
          text
          @click="$emit('addProduct')"
        >
          Dodaj produkt
        </v-btn>
        <v-btn
          v-if="client"
          color="primary"
          text
          :disabled="!selectedAddress"
          @click="copyProducts"
        >
          Skopiuj produkty
        </v-btn>
        <v-autocomplete
          v-if="client"
          v-model="selectedAddress"
          :items="clientAddressesWithProducts"
          v-bind="autocompleteAttrs"
          placeholder="Lokalizacje klienta"
          item-text="formattedAddress"
          :rules="[]"
          return-object
          hide-details
          clearable
        />
      </div>
    </v-col>
    <v-col
      cols="12"
      class="products-list pt-2 mt-2 ml-2 mb-4"
    >
      <span
        class="ml-1"
        :class="{'mb-4': products.length }"
      >Produkty {{ !products.length ? '(brak)' : '' }}</span>
      <v-row
        v-for="(product, index) in products"
        :key="'product'+index"
      >
        <v-col cols="3">
          <v-autocomplete
            :value="products[index].containerTypeId"
            :items="containerTypes"
            label="Typ kontenera"
            placeholder="Typ kontenera"
            v-bind="autocompleteAttrs"
            @change="updateProduct(index, 'containerTypeId', $event)"
          />
        </v-col>

        <v-col cols="5">
          <v-autocomplete
            :value="products[index].debrisTypeId"
            :items="debrisAndAggregateTypes"
            label="Typ odpadu/kruszywa"
            placeholder="Typ odpadu/kruszywa"
            v-bind="autocompleteAttrs"
            :filter="searchDebrisType"
            :item-text="getDebrisString"
            multiple
            :search-input.sync="searchValue[index]"
            @change="updateProduct(index, 'debrisTypeId', $event)"
          >
            <template #selection="{ item, index:number }">
              <MultiselectChoices
                :index="number"
                :label="item.code"
                :array-length="products[index].debrisTypeId.length"
              />
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="3">
          <v-text-field
            :value="products[index].netValue"
            outlined
            type="number"
            label="Cena netto"
            :rules="[rules.required, rules.money, rules.lowerThan(10000001)]"
            placeholder="Wpisz cenę [zł]"
            @wheel="$event.target.blur()"
            @change="updateProduct(index, 'netValue', Number($event) || 0)"
          />
        </v-col>

        <v-col
          cols="1"
          class="pl-0"
        >
          <v-btn
            icon
            @click="removeProduct(index)"
          >
            <img
              width="24"
              src="@/assets/icons/block-mark.svg"
              alt="blocked"
            >
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import MultiselectChoices from '../../Elements/MultiselectChoices'
import rules from '../../../utils/validators'
import { mapState, mapActions } from 'vuex'
import { searchDebrisType, getDebrisString } from '../../../utils'

export default {
  components: {
    MultiselectChoices
  },
  props: {
    products: {
      type: Array,
      default: () => []
    },
    editingAddress: {
      type: Boolean,
      default: false
    },
    client: {
      type: Object,
      default: () => null
    },
  },
  data() {
    return {
      rules,
      autocompleteAttrs: {
        itemText: 'name',
        itemValue: 'id',
        outlined: true,
        dense: true,
        rules: [rules.required]
      },
      selectedAddress: null,
      searchValue: {},
    }
  },
  mounted() {
    if (!this.editingAddress) {
      this.getContainerTypes()
      this.getDebrisTypes({ params: { aggregate: false } })
      this.getAggregateTypes({ params: { endpoint: 'debrisTypes', aggregate: true } })
    }
  },
  computed: {
    ...mapState({
      containerTypes: state => state.containerTypes.items,
      debrisTypes: state => state.debrisTypes.items,
      aggregateTypes: state => state.aggregateTypes.items,
    }),
    debrisAndAggregateTypes () {
      return [...this.debrisTypes, ...this.aggregateTypes]
        .sort((a, b) => a.name?.localeCompare(b.name))
    },
    clientAddressesWithProducts () {
      return this.client?.addresses.filter(address => address.products.length) || []
    }
  },
  methods: {
    searchDebrisType,
    getDebrisString,
    ...mapActions({
      getContainerTypes: 'containerTypes/getItems',
      getDebrisTypes: 'debrisTypes/getItems',
      getAggregateTypes: 'aggregateTypes/getItems',
      getCommunes: 'communes/getItems',
    }),
    removeProduct (index) {
      this.$emit('removeProduct', index)
    },
    updateProduct (index, key, value) {
      this.searchValue = {}
      this.$emit('updateProduct', { index, key, value })
    },
    copyProducts() {
      const products = this.selectedAddress.products.map(product => ({ netValue: product.netValue / 100, debrisTypeId: [product.debrisType.id], containerTypeId: product.containerType.id }))
      this.$emit('copyProducts', products)
      this.selectedAddress = null
    },
  }
}
</script>

<style lang="scss" scoped>
.products-list {
  max-height: 400px;
  overflow-y: scroll;
  background: rgba(55, 200, 122, 0.1);
  border-radius: 4px;
  max-width: 550px;

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  span {
    font-weight: 800;
    font-size: 12px;
    display: block;
    color: $color-text-dark-grey;
  }
}
</style>

<template>
  <v-form
    class="w-100"
    ref="addNewAddressForm"
    v-model="isAddressFormValid"
    @submit.prevent="submitAddressForm"
  >
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          :items="departmentsList"
          :disabled="!!forcedDepartment"
          label="Oddział"
          v-model="address.departmentId"
          placeholder="Wybierz oddział lokalizacji"
          v-bind="autocompleteAttrs"
        />
      </v-col>

      <v-col
        cols="12"
        class="pb-0"
      >
        <gmap-autocomplete
          class="introInput"
          @place_changed="getAddressData"
          :options="autocompleteOptions"
          select-first-on-enter
        >
          <template #input="slotProps">
            <div class="d-flex">
              <v-text-field
                outlined
                prepend-inner-icon="place"
                placeholder="Wyszukaj adres"
                label="Adres lokalizacji"
                :value="address.address.formattedAddress"
                hide-details
                ref="input"
                @listeners="slotProps.listeners"
                @attrs="slotProps.attrs"
              >
                <template #prepend-inner>
                  <Icon
                    name="search"
                    size="small"
                    class="mr-2"
                    @click="showProps(slotProps)"
                  />
                </template>
              </v-text-field>
              <v-tooltip
                :disabled="!!address.address.formattedAddress"
                bottom
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      class="ml-2"
                      icon
                      medium
                      :disabled="!address.address.formattedAddress"
                      color="primary"
                      target="_blank"
                      @click="toggleMap"
                    >
                      <v-icon
                        v-if="isMapVisible"
                        medium
                      >
                        mdi-map-minus
                      </v-icon>
                      <v-icon
                        v-else
                        medium
                      >
                        mdi-map
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>Wyszukaj najbliszy adres by otworzyć mapę</span>
              </v-tooltip>
            </div>
          </template>
        </gmap-autocomplete>
      </v-col>

      <v-col
        v-if="isMapVisible"
        cols="12"
        class="pb-0"
      >
        <GmapMap
          :center="mapCenter"
          :zoom="15"
          map-type-id="roadmap"
          class="map"
          @click="setMapAddressLocation"
        >
          <GmapMarker
            :position="mapAddressLocation"
            :clickable="true"
            :draggable="true"
            @drag="setAddressLocation"
          />
        </GmapMap>
      </v-col>
      <v-col
        cols="12"
        class="d-flex align-start py-0"
      >
        <v-input
          :value="choosenAddress"
          :rules="[rules.required]"
          hide-details
          class="validated-label"
        >
          <div class="mr-1">
            Wybrany adres:
          </div>
          <span class="font-weight-medium">{{ choosenAddress || '-' }}</span>
        </v-input>
      </v-col>

      <v-col
        cols="12"
        class="pt-0"
      >
        <v-checkbox
          v-model="showOwnNameField"
          label="Zmień nazwę lokalizacji"
          hide-details
          small
          class="mt-0 pt-0"
          :class="{ 'pb-2': !showOwnNameField }"
        />
      </v-col>

      <v-expand-transition>
        <v-col
          cols="12"
          v-if="showOwnNameField"
          class="pt-2"
        >
          <v-text-field
            outlined
            label="Nazwa lokalizacji"
            v-model="address.name"
            validate-on-blur
            placeholder="Wpisz własną nazwę"
            class="mt-2"
          />
        </v-col>
      </v-expand-transition>

      <v-col
        cols="12"
        class="pb-0"
      >
        <v-autocomplete
          v-model="address.communeId"
          :items="communes"
          label="Gmina"
          placeholder="Wybierz gminę"
          v-bind="autocompleteAttrs"
          :item-text="item => `${item.name} ${item.communeType ? '(' + item.communeType + ')' : ''}`"
          :rules="[]"
          hide-details
          clearable
        />
      </v-col>

      <v-col
        cols="12"
        class="pt-0"
      >
        <div class="d-flex align-center">
          <span class="input-label">
            Dodaj gminę
          </span>

          <v-switch
            v-model="showCommuneForm"
            hide-details
            class="ml-2 my-0 pa-0"
          />
        </div>
        <v-expand-transition>
          <AddCommune
            v-if="showCommuneForm"
            small
            @communeAdded="updateCommune"
          />
        </v-expand-transition>
      </v-col>
      <v-col
        cols="12"
        v-if="!editingAddress"
      >
        <v-text-field
          outlined
          label="Osoba kontaktowa"
          v-model="address.contactName"
          placeholder="Wpisz imię i nazwisko osoby do kontaktu"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined
          label="Telefon"
          v-model="address.phoneNumber"
          :rules="[rules.phoneNumber]"
          v-mask="getFieldMask('phoneNumber')"
          placeholder="Wpisz numer telefonu"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined
          label="Email"
          v-model="address.email"
          :rules="[rules.email]"
          validate-on-blur
          placeholder="Wpisz adres email"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          outlined
          label="Rabat netto [zł]"
          v-model.number="address.discount"
          type="number"
          :rules="[rules.money, rules.lowerThan(10000001)]"
          validate-on-blur
          placeholder="0zł"
          hide-details
          @wheel="$event.target.blur()"
        />
      </v-col>
      <v-col
        cols="8"
        class="pb-0"
      >
        <v-text-field
          outlined
          label="Uzasadnienie rabatu"
          v-model="address.discountJustification"
          placeholder="Wpisz uzasadnienie rabatu"
          hide-details
        />
      </v-col>

      <v-col
        v-if="paymentTypeVisible"
        cols="12"
        class="py-0 mt-4"
      >
        <PaymentType
          :payment-data="address"
          not-required
          :payment-due-date-visible="false"
          @update="updatePaymentType"
        />
      </v-col>

      <v-col
        cols="12"
        class="mt-3"
      >
        <v-textarea
          outlined
          :rows="2"
          auto-grow
          label="Notatka do lokalizacji"
          v-model="address.notes"
          placeholder="Wpisz notatkę do adresu"
        />
      </v-col>

      <v-col
        cols="12"
      >
        <v-textarea
          outlined
          :rows="2"
          auto-grow
          label="Notatka do faktury"
          v-model="address.invoiceNotes"
          placeholder="Wpisz notatkę do faktury"
        />
      </v-col>

      <v-col
        cols="12"
        class="mb-0 pb-0"
      >
        <v-textarea
          outlined
          :rows="2"
          auto-grow
          hide-details
          label="Uwagi dla spedytora"
          v-model="address.remarks"
          placeholder="Wpisz uwagi"
        />
      </v-col>
      <template v-if="(editAddressProducts || !editingAddress) && clientType !== 'Osoba fizyczna'">
        <AddressProducts
          :client="client"
          :products="address.products"
          @addProduct="addProduct"
          @updateProduct="updateProduct"
          @removeProduct="removeProduct"
          @copyProducts="copyProducts"
        />
      </template>

      <v-flex
        v-if="actions"
        class="mt-1 ml-2"
      >
        <v-btn
          color="black"
          text
          small
          @click="$emit('close')"
        >
          Anuluj
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          text
          small
        >
          Zapisz lokalizację
        </v-btn>
      </v-flex>
    </v-row>
  </v-form>
</template>

<script>
import AddCommune from './AddCommune'
import PaymentType from './PaymentType'
import AddressProducts from '../Inputs/AddressProducts'
import rules from '../../../utils/validators'
import { getGmapsAddressParts, parseAsBasicUnit, getFieldMask, searchDebrisType, getDebrisString, formatChangedPhoneNumber } from '../../../utils'
import { Address } from '../../../models'
import { mapState, mapActions } from 'vuex'
import set from 'lodash/set'

export default {
  props: {
    actions: {
      type: Boolean,
      default: false
    },
    forcedDepartment: {
      type: [String, Number],
      required: false
    },
    editingAddress: {
      type: Object,
      required: false
    },
    paymentTypeVisible: {
      type: Boolean,
      default: true
    },
    client: {
      type: Object,
      required: false
    },
    editAddressProducts: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AddCommune,
    PaymentType,
    AddressProducts,
  },
  data () {
    return {
      rules,
      isAddressFormValid: true,
      showCommuneForm: false,
      address: new Address(),
      showOwnNameField: false,
      autocompleteOptions: {
        fields: ['adr_address', 'formatted_address', 'geometry.location'],
        componentRestrictions: {
          country: ['pl']
        }
      },
      autocompleteAttrs: {
        itemText: 'name',
        itemValue: 'id',
        outlined: true,
        dense: true,
        rules: [rules.required]
      },
      isMapVisible: false,
      mapAddressLocation: null,
      mapCenter: { lat: 51.118860, lng: 16.976440 }
    }
  },
  watch: {
    'address.phoneNumber' (value) {
      if (value && !value.includes('-') && value.length === 9) {
        this.$nextTick(() => {
          this.address.phoneNumber = '' + this.formatChangedPhoneNumber(value)
        })
      }
    }
  },
  computed: {
    ...mapState({
      departmentsList: state => state.departments.items,
      communes: state => state.communes.items,
      filters: state => state.core.filters,
      clientType: state => state.client.entity.clientType,
    }),
    choosenAddress () {
      const { lat, lng, formattedAddress } = this.address.address
      return lat && lng && formattedAddress
    },
  },
  created () {
    if (this.editingAddress) {
      const { name, formattedAddress } = this.editingAddress
      this.showOwnNameField = name && name !== formattedAddress
      const baseAddress = { ...this.editingAddress }
      baseAddress.discount = baseAddress.discount / 100
      baseAddress.products = baseAddress.products.map(product => ({
        netValue: product.netValue / 100,
        containerTypeId: product?.containerType?.id || product.containerTypeId,
        debrisTypeId: product?.debrisType?.id || product.debrisTypeId,
        id: product.id || null,
      })).sort((a, b) => a.debrisTypeId - b.debrisTypeId)
      this.address = new Address(baseAddress)
    }
  },
  mounted () {
    if (this.forcedDepartment) this.address.departmentId = this.forcedDepartment
    this.getCommunes()
  },
  methods: {
    ...mapActions({
      getCommunes: 'communes/getItems',
    }),
    getFieldMask,
    searchDebrisType,
    getDebrisString,
    formatChangedPhoneNumber,
    submitAddressForm () {
      this.$refs.addNewAddressForm.validate()
      if (this.isAddressFormValid) {
        const { discount, products, ...addressParams } = this.address
        if (!this.showOwnNameField) addressParams.name = addressParams.address.formattedAddress
        const parsedProducts = [...products].map(product => { // deep copy was needed, shallow copy was not enough
          product.netValue = parseAsBasicUnit(product.netValue) || 0
          if (Array.isArray(product.debrisTypeId)) {
            return product.debrisTypeId.map(debrisTypeId => ({ ...product, debrisTypeId }))
          }
          return product
        }).flat()
        const address = {
          ...addressParams,
          discount: parseAsBasicUnit(discount) || 0,
          products: parsedProducts
        }
        if (this.editingAddress) {
          this.$emit('editAddress', address)
        } else {
          this.$emit('addNewAddress', address)
        }
      }
    },
    getAddressData (e) {
      const { formattedAddress, lat, lng } = getGmapsAddressParts(e)
      this.mapAddressLocation = { lat: Number(lat), lng: Number(lng) }
      this.mapCenter = { lat: Number(lat), lng: Number(lng) }
      this.address.address = { formattedAddress, lat, lng }
      this.address.name = formattedAddress
    },
    toggleMap () {
      this.isMapVisible = !this.isMapVisible
      if (this.isMapVisible && this.editingAddress) this.setEditingAddressOnMap()
    },
    setEditingAddressOnMap () {
      const { lat, lng } = this.editingAddress.location
      const location = { lat: Number(lat), lng: Number(lng) }
      this.mapAddressLocation = { lat: Number(lat), lng: Number(lng) }
      this.setMapCenter(location)
    },
    setMapAddressLocation (e) {
      const location = { lat: e.latLng.lat(), lng: e.latLng.lng() }
      this.mapAddressLocation = location
      this.address.address.lat = String(location.lat)
      this.address.address.lng = String(location.lng)
    },
    setMapCenter(location) {
      this.mapCenter = location
    },
    setAddressLocation(location) {
      this.address.address.lat = String(location.latLng.lat())
      this.address.address.lng = String(location.latLng.lng())
    },
    copyClientData (data) {
      this.address = {
        ...this.address,
        ...data
      }
    },
    updateCommune (id) {
      this.address.communeId = id
      this.showCommuneForm = false
    },
    updatePaymentType (data) {
      const key = data?.key?.split('.').pop() // key is 'payment.label'
      set(this.address, key, data.value)
    },
    addProduct () {
      const payload = {
        debrisTypeId: null,
        containerTypeId: null,
        netValue: null
      }

      if (this.editAddressProducts) payload.id = null

      this.address.products.push(payload)
    },
    removeProduct (index) {
      this.address.products.splice(index, 1)
    },
    copyProducts(products) {
      this.address.products.push(...products)
    },
    updateProduct({ index, key, value }) {
      this.address.products[index][key] = value
    },
  }
}
</script>

<style lang="scss" scoped>
.products-list {
  max-height: 400px;
  overflow-y: scroll;
  background: rgba(55, 200, 122, 0.1);
  border-radius: 4px;
  max-width: 567px;

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  span {
    font-weight: 800;
    font-size: 12px;
    display: block;
    color: $color-text-dark-grey;
  }
}

.map {
  width: 100%;
  height: 300px;
  border: 2px solid $color-indicator-gray;
  border-radius: 4px;
}
</style>
